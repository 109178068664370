import * as React from "react";
// import HomePage from "../templates/home_page";
import Seo from "../components/seo";
import { useSiteMetadata } from "../hooks/defaults/Use-SiteMetadata";

import { useHomePage } from "../hooks/defaultsPages/Use-Home-Page";

import Carousel from "../components/header/carousel";
import FirstFive from "../components/includes/localServices/who_we_are";

import CTAMain from "../components/CTA/CTAMain";
import Branches from "../components/includes/MainAreas";
import ServiceSlider from "../components/includes/service_slider";
import WhereWeAre from "../components/includes/where_we_are";
import LastOne from "../components/includes/localServices/cta_one";

import Layout from "../components/Defaults/Layout";
import SecondFour from "../components/includes/localServices/cta_four";

export default function HomePage() {
	const { googleMap, mainArea } = useSiteMetadata();
	const data = useHomePage();
	const firstFive = data.slice(0, 5);
	const secondFour = data.slice(5, 9);
	const lastOne = data.slice(9, 10);
	return (
		<Layout>
			<Carousel />
			<CTAMain background={true} />
			<Branches />
			<ServiceSlider />
			<FirstFive data={firstFive} />

			<WhereWeAre gMap={googleMap ? googleMap : ""} area={mainArea} />
			<SecondFour data={secondFour} split={true} />
			{lastOne && lastOne.length > 0 ? <LastOne data={lastOne} /> : null}
		</Layout>
	);
}
/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => {
	const { mainService, mainArea } = useSiteMetadata();
	return <Seo title={`${mainService} Services in ${mainArea}`} />;
};
