import { useStaticQuery, graphql } from "gatsby";

export const useServiceSlider = () => {
	const { allServicesCsv } = useStaticQuery(
		graphql`
			query useServiceSlider {
				allServicesCsv {
					group(field: { Article_Title: SELECT }, limit: 1) {
						nodes {
							Article_Title
							Output
							related_service
						}
					}
				}
			}
		`
	);
	console.log("allServicesCsv", allServicesCsv);
	return allServicesCsv?.group;
};
