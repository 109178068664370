import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useSiteMetadata } from "../../hooks/defaults/Use-SiteMetadata";
var slugify = require("slugify");

const Header = () => {
	const { mainArea } = useSiteMetadata();
	const { allFile } = useStaticQuery(graphql`
		query HeadingCarousel {
			allFile(
				sort: { modifiedTime: DESC }
				filter: {
					extension: { regex: "/(jpg)|(jpeg)|(png)/" }
					sourceInstanceName: { eq: "serviceIMGs" }
				}
			) {
				group(field: { relativeDirectory: SELECT }, limit: 1) {
					edges {
						node {
							id
							relativeDirectory
							childImageSharp {
								gatsbyImageData(
									placeholder: BLURRED
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
	`);

	return (
		<>
			<Carousel
				infiniteLoop={true}
				showArrows={true}
				showThumbs={false}
				showStatus={false}
				swipeAble={true}
				emulateTouch={true}
				autoPlay
				lazyLoad={true}
			>
				{allFile.group.map((node, index) => {
					return (
						<div className="relative   overflow-hidden " key={index}>
							<div className="absolute inset-0 z-10 bg-slate-800 text-center flex flex-col  justify-center bg-opacity-50 hover:bg-opacity-80 duration-700">
								<h2 className=" text-white text-3xl hover:underline drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
									<Link
										to={`/${slugify(node.edges[0].node.relativeDirectory, {
											lower: true,
										})}`}
									>
										{node.edges[0].node.relativeDirectory} in {mainArea}
									</Link>
								</h2>
								{/* <p className="mt-2 mx-auto text-white text-xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
									We specialize in {node.edges[0].node.relativeDirectory} in
									Surrey
								</p> */}
							</div>
							<div className=" flex flex-wrap content-center">
								<GatsbyImage
									loading={index === 0 ? "lazy" : "eager"}
									style={{ height: "100%", width: "100%" }}
									image={getImage(node.edges[0].node.childImageSharp)}
									className="mx-auto "
									alt={node.edges[0].node.relativeDirectory}
								/>
							</div>
						</div>
					);
				})}
			</Carousel>
		</>
	);
};

export default Header;
