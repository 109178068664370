import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useSiteMetadata } from "../../hooks/defaults/Use-SiteMetadata";
var slugify = require("slugify");

const Areas = () => {
	const data = useStaticQuery(graphql`
		query AllGovAreas {
			allBranchesCsv {
				allGOVareas: group(field: { local_government_area: SELECT }) {
					fieldValue
					field
				}
			}
		}
	`);
	const { mainArea } = useSiteMetadata();

	return (
		<>
			<div className="mt-6 mx-auto  max-w-screen-xl items-center mb-10 justify-between px-4">
				<div className="bg-slate-800 my-10 shadow-xl p-10 max-h-96 text-center overflow-auto rounded outline outline-[4px] hover:outline-[5px] outline-blue-500/20 hover:outline-blue-500/80 outline-offset-[6px] hover:outline-offset-0 duration-300">
					<h3>
						<span className="font-bold mb-4 text-xl  text-white">
							Our Local Areas in {mainArea}
						</span>
					</h3>
					<div className="mt-6 grid grid-cols-1 md:grid-cols-6 gap-6 ">
						{data?.allBranchesCsv?.allGOVareas?.map((localArea, indx) => {
							var paths = slugify(localArea?.fieldValue, { lower: true });
							return (
								<div className="col-span-1    md:col-span-2 text-center   rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300">
									<Link
										to={`/${paths}`}
										className="
                   text-white text-base hover:text-blue-400 underline"
										href={`#${localArea?.fieldValue}`}
									>
										{localArea?.fieldValue}
									</Link>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};
export default Areas;
