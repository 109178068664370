import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "gatsby";
import { useServiceSlider } from "../../hooks/defaults/Use-ServiceSlider";
import { useSiteMetadata } from "../../hooks/defaults/Use-SiteMetadata";

var slugify = require("slugify");

const ServiceSlider = () => {
	const { title, mainService } = useSiteMetadata();
	const services = useServiceSlider();

	const settings = {
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		arrows: false,
		dots: true,
		lazyLoad: true,
		autoplay: false,
		autoplaySpeed: 2500,
		pauseOnHover: true,
		cssEase: "linear",
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
	};
	return (
		<div className="mt-20 mx-auto  max-w-screen-xl items-center mb-20 justify-between px-4">
			<h3 className="text-3xl mb-10  text-blue-500 text-center pb-4  border-b-2">
				{mainService} Services From {title}
			</h3>
			<div className=" w-full">
				<Slider {...settings}>
					{services.map((service, index) => {
						return (
							<div className="p-4 pb-10" key={index}>
								<h4 className="mt-4">
									<Link
										className="text-blue-600 text-lg hover:underline"
										to={`/${slugify(service.nodes[0].related_service, {
											lower: true,
										})}`}
									>
										{service.nodes[0].Article_Title}
									</Link>
								</h4>
								<p className=" mt-6 mb-2 text-gray-700  font-thin ">
									{service.nodes[0].Output.substring(0, 400 - 3) + "..."}
								</p>
								<Link
									className=" text-blue-600 justify-center items-center hover:underline"
									to={`/${slugify(service.nodes[0].related_service, {
										lower: true,
									})}`}
								>
									View Our {service.nodes[0].Article_Title} Service
								</Link>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default ServiceSlider;
