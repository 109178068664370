import { useStaticQuery, graphql } from "gatsby";

export const useHomePage = () => {
	const { allHomePageCsv } = useStaticQuery(
		graphql`
			query HomePage {
				allHomePageCsv {
					nodes {
						Article_Intro
						Article_Title
						Output
						company_name
						country
						county
						easting
						email
						grid_reference
						level
						latitude
						local_government_area
						longitude
						name
						nuts_region
						northing
						phone_number
						postcode_sector
						related_service
						service_level
						site
						title
						type
					}
				}
			}
		`
	);
	return allHomePageCsv?.nodes;
};
